<template>
  <!-- <div>
  <div>
    <div v-if="loader">Loading...</div>
    <div v-if="!loader">
      <div v-if="global_err == ''">
        <h1>Login</h1>
        <form>
          <div>
            Email:<br />
            <input type="text" />
          </div>
          <div>
            Password:<br />
            <input type="password" v-model="password" />
          </div>
          <div className="submit_cont">
            <input v-on:click="login" type="submit" />
          </div>
          <div className="submit_cont">
            <input
              type="button"
              value="Forgot password"
              v-on:click="forgot_password"
            />
          </div>
          <div>{{ errs }}</div>
        </form>
      </div>
    </div>
  </div> -->

  <div class="sign__wrapper">
    <BackofficeGlobalHeader :global_err="global_err" />
    <div class="inner__sign">
      <form action="">
        <div class="sign__head">
          <h2 class="secondary__font">Bigger Fish</h2>
          <p>Welcome! Please enter your details</p>
        </div>
        <div class="group__input">
          <p>Email</p>
          <input
            type="text"
            placeholder="Enter your email"
            class="secondary__font"
            v-model="email"
          />
        </div>
        <div class="group__input">
          <p>Password</p>
          <input
            type="password"
            placeholder="•••••••"
            class="secondary__font"
            v-model="password"
          />
        </div>
        <div class="error" v-if="email_err">
          <p>An Email address must contain a single @ and .</p>
        </div>
        <div class="error" v-if="general_err">
          <p>Please try again...</p>
        </div>
        <div class="group__submit">
          <button type="" class="regular-btn" v-on:click="login">
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import BackendModel from "../../models/BackendModel";
import BackofficeGlobalHeader from "./BackofficeGlobalHeader";
// import axios from 'axios'

// const url = 'http://ipinfo.io/json'
export default {
  name: "Login",
  data() {
    return {
      loader: false,
      email: "",
      password: "",
      errs: "",
      global_err: "",
      results: [],
      email_err: false,
      general_err: false,
    };
  },
  components: { BackofficeGlobalHeader },
  async mounted() {
    const backendModel = new BackendModel();

    this.loader = true;
    let backoffice_session_id = backendModel.getBackofficeSessionId();
    let authRes = await backendModel.backendRequest(
      "/Api/service/backoffice/auth",
      { backoffice_session_id }
    );
    if (typeof authRes == "undefined") {
      this.global_err = "Backend is down?";
    } else if (authRes.data.res == "ERROR") {
      this.global_err = authRes.data.e;
    } else if (authRes.data.res == "OK") {
      await this.$router.push({ path: "/backoffice/statistics" });
    }
    this.loader = false;
  },
  methods: {
    ValidateEmail(mail) {
      let validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (mail.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    async login(e) {
      e.preventDefault();
      this.loader = true;
      if (this.ValidateEmail(this.email) ==false) {
        this.email_err = true;
        // alert("sddsdd")
      } else {
        try {
          const backendModel = new BackendModel();
          let res = await backendModel.backendRequest(
            "/Api/service/backoffice/login",
            { email: this.email, password: this.password }
          );
          console.log(res);
          if (res.data.res == "ERROR") {
            this.global_err = res.data.e;
          } else if (res.data.res == "NOK") {
            this.general_err = true
            this.errs = "Please try again...";
          } else {
            this.errs = "";
            let CookieDate = new Date();
            CookieDate.setFullYear(CookieDate.getFullYear() + 10);
            document.cookie =
              "backoffice_session_id=" +
              res.data.session_id +
              "; expires=" +
              CookieDate.toUTCString() +
              ";";
            await this.$router.push({ path: "/backoffice/statistics" });
          }
          this.loader = false;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async forgot_password() {
      await this.$router.push({ path: "/backoffice/forgot_password" });
    },
  },
};
</script>

<style scoped>
@import "./style.css";
</style>
