<template>
  <div>
    <h1>{{ global_err }}</h1>
  </div>
</template>

<script>
export default {
  name: 'GlobalHeader',
  components: {
  },
  props : ['global_err']
}
</script>